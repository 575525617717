<template>
  <div class="container">
    页面不存在
    <a href="/">返回首页</a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  text-align: center;
  margin-top: 200px;
}
</style>
